.list-container{
    max-width: calc(95vw - 200px);
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 0;
    margin-right: 5%;
    margin-left: 30px;
    margin-top: 23px;
    margin-bottom: 50px;
}
.row-title, .producer-row{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F8F8F8;
    margin: 0;
    padding: 10px;
    white-space: nowrap;
    margin: 0;
    height: 23px;
}
.row-title{
    justify-content: flex-start;
}
.producer-column{
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 320px;
}
.fixed-column{
    display: flex;
    flex-direction: column;
    margin: 0;
    background-color: #F8F8F8;
    color: #002c3d;
    font-weight: 700;
}
.content-list{
    display: flex;
    overflow-x: scroll;
}
.empty-cell, .producer-row-header{
    display: inline-block;
    padding: 10px;
    background-color: #F8F8F8;
    text-align: center;
    font-weight: 700;
    color: #002c3d;
    border: 1px solid #F8F8F8;
    margin: 0;
    height: 23px;
    white-space: nowrap;
}

.producer-row-header Link{
    text-decoration: none;
}
.filter-bar{
    position: fixed;
    top: 100px;
    left: 0;
    height: 200px;
    width: 200px;
    color: white;
}
.filter-bar div{
    margin-left: 20px;
    display: flex;
    flex-direction: row;
}
.filter-bar input{
    width: fit-content;
    margin-right: 8px;
}
.filter-title{
    font-weight: 700;
    margin-top: 50px;
}
.list-button{
    background-color: #8cc20b;
    color: white;
    padding: 5px 15px 5px 15px;
    font-size: 16px;
    line-height: 1.6;
    border: none;
    text-decoration: none;
    margin: 5px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 125px;
    margin-right: 20px;
    cursor: pointer;
}
.list-button:hover{
    background-color: #699208;
}
.reset-section-button {
    background-color:#01415a;
}

.reset-section-button:hover {
    background-color: #043143;
}
.producer-documents {
    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;
}
.logo-cell{
    height: 100px;
}

.logo-cell img{
    height: 100px;
    max-width: 280px;
}
.product-description-container{
    height: 120px;
    
}
.product-description-container textarea{
    box-sizing: border-box;
    width: 300px;
    resize: none;
    overflow: hidden;
    border: none;
    outline: none;
    background-color: inherit;
    cursor: pointer;
    background: -webkit-linear-gradient(black, lightgrey);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.person-name-field{
    font-weight: 700;
    color: #01415a;
    cursor: pointer;
}
.item-alignment{
    justify-content: flex-end;
}
.document-alignment-left{
    align-items: flex-start;
}