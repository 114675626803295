.NavigationLink {
    box-sizing: border-box;
    display: block;
    padding: 10px;
}
.NavigationLink > a {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    color: black;
    padding-bottom: 11px;
}

.orange {
    border-bottom-style: solid;
    border-width: 5px;
    border-color: #ff8c00;
}
.green {
    border-bottom-style: solid;
    border-width: 5px;
    border-color: #8cc20b;
}