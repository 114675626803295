.DefaultModal {
    position: fixed;
    z-index: 1002;
    left: 25%;
    top: 30%;
    border-style: solid;
    border-color: #39b4e0;
    background-color: #f5f5f5;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
    width: 50%;
}

.ModalLabel {
    padding: 15px;
    margin: -1px;
    background-color: #39b4e0;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

.ModalLabel img {
    max-width: 20px;
    top: 10px;
    right: 10px;
    position: absolute;
}

.ModalInfoRow {
    color: gray;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    font-style: italic;
}

.ModalInfoBlackbox {
    background-color: black;
    min-width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 12px;
    margin-top: 3px;
}

.ModalInfoRow img {
    max-width: 5px;
    max-height: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
}

.ModalTextarea {
    width: 100%;
    font-family: sans-serif;
    display: inline-block;
    resize: none;
}

.ModalButtonRow {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 5px;
}

.ModalOverlay {
    z-index: 1001;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: fixed;
    top: 0;
    left: 0;
}

.ModalContent {
    margin: 20px 25px 20px 20px;
}

