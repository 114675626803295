.Up {
    transform: rotate(180deg);
    width: 15px;
    margin-left: auto;
    padding-right: 12px;
}

.Down {
    width: 15px;
    margin-left: auto;
    padding-left: 12px;
    z-index: 10;
}
