.error-header{
    background-color: firebrick;
    color: white;
    padding-left: 5px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.exclamation-mark{
    height: 15px;
    margin-right: 8px;
}
.error-message{
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: firebrick;
    padding-left: 5px;
}