.Details-Container{
    padding-right: 5%;
    margin-bottom: 30px;
}
.Details-Name{
    padding-left: 30px;
    margin-bottom: 10px;
}

.Details-Textarea{
    width: 100%;
    padding-left: 30px;
    box-sizing: border-box;
}

#Details{
    height: auto;
    width: 100%;
    resize: none;
    box-sizing: border-box;
}
.Price-Table-Name, .Contac-Table-Name{
    padding-left: 30px;
    margin-bottom: 10px;
}

.Footer-Container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Price-Container, .Contact-Person-Container{
    padding-right: 5%;
    margin-bottom: 30px;
}

.Price-Table, .Contact-Person-Table{
    padding-left: 30px;
    box-sizing: border-box;
}

.Buttons-Space{
    height: 50px;
}