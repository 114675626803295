.RequirementControl {
    margin-bottom: 10px;
    padding: 15px 20px 15px 20px;
    background-color: white;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.06), 0 8px 20px rgba(0, 0, 0, 0.025);
    justify-content: space-between;
}

.RequirementIconControl {
    white-space: nowrap;
}

.RequirementIconControl img {
    vertical-align: middle;
}

.Icon {
    width: 15px;
    margin-left: auto;
    padding-left: 12px;
}

.Pen {
    width: 12px;
    margin-left: auto;
    padding-left: 12px;
}

.Exclamation {
    width: 5px;
    margin-left: auto;
    padding-left: 12px;
}
