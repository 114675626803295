.NewRequirement {
    margin: 2px;
    padding: 15px;
}

.Cross {
    margin: 10px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #8cc20b;
}

.Cancel {
    width: 25px;
    margin-left: auto;
    vertical-align: middle;
}

.RequirementFilter {
    padding: 10px;
}

.NewRequirement .Button,
.RequirementFilter .Button {
    display: initial;
}

.RequirementFilter .RequirementDropDownForm {
    display: inline-block;
}

.MultiLanguageDescriptions {
    display: flex;
    justify-content: space-between;
}

.MultiLanguageDescriptions textarea {
    flex-grow: 1;
    margin-right: 5px;
}
