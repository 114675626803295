.interested-ind-list-container{
    width: 90%;
    margin: 5%;
}

.interested-ind-list-container table{
    width: 100%;
}
.interested-ind-list-container table, .interested-ind-list-container td, .interested-ind-list-container th{
    border: 1px solid white;
    border-collapse: collapse;
    padding: 5px;
}

.interested-ind-list-container th {
    text-align: start;
    background-color: #002c3d;
    color: white;
    font-size: large;
}
.interested-ind-list-container tr{
    background-color: rgb(203, 218, 228);
    color: #002c3d;
    font-size: large;
    font-weight: 600;
}
.interested-ind-list-container tr:nth-child(even){
    background-color: rgb(231, 237, 242);
}
.interested-ind-list-container select {
    color: inherit;
    opacity: 1;
    -webkit-appearance: none;
    border: none;
    background-color: inherit;
    font-size: large;
    font-weight: 600;
}