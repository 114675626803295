.Toggle {
    margin-top: 30px;
    padding: 15px 15px 15px 15px;
}

/*Header*/
.Toggle-Head {
    position: relative;
    padding: 5px 20px 5px 20px;
    text-transform: uppercase;
    background-color: #f5f5f5;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.06), 0 8px 20px rgba(0, 0, 0, 0.025);
    cursor: pointer;
    user-select: none;
    transition: color .2s;
    display: flex;
}

.Toggle-Head:hover {
    color: #8cc20b;
}

.Toggle-Head h3 {
    font-size: 22px;
}

.h3, h3 {
    line-height: 26px;
    font-weight: 700;
}

.Toggle-Orange {
    background-color: #db9512;
    color: white;
}

.Toggle-Orange:hover {
    color: grey;
}

.Toggle-Head.Toggle-Orange:hover .Toggle-Icon.Toggle-Orange::before,
.Toggle-Head.Toggle-Orange:hover .Toggle-Icon.Toggle-Orange::after {
    background-color: grey;
}

.Toggle-Icon.Toggle-Orange::before,
.Toggle-Icon.Toggle-Orange::after {
    background-color: white;
}

/*Cross*/
.Toggle-Icon {
    top: 13px;
    position: absolute;
    right: 20px;
    width: 30px;
    height: 30px;
}

.Toggle-Icon::after {
    width: 2px;
    height: 25px;
}

.Toggle-Icon::before {
    width: 25px;
    height: 2px;
}

.Toggle-Icon::before,
.Toggle-Icon::after {
    content: '';
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4d4d4d;
    transition: background-color .2s, height .2s;
}

*, ::before, ::after {
    box-sizing: inherit;
}

.Toggle-Head:hover .Toggle-Icon::before,
.Toggle-Head:hover .Toggle-Icon::after {
    background-color: #8cc20b;
}

.Toggle-Active .Toggle-Icon::after {
    height: 0;
}

.Toggle-Content {
    padding: 20px;
    margin-top: 8px;
    background-color: #fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.06), 0 8px 20px rgba(0, 0, 0, 0.025);
}
.StatusIcon {
    position: absolute;
    right: 60px;
    top: 30px;
}
.StatusPen {
    width: 15px;
}

.StatusCheck {
    width: 20px;
}

.StatusExclamation {
    width: 8px;
}
