.RequirementViewBox {
    border: solid 2px #7f7f7f;
    margin: 2px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.RequirementShow {
    display: flex;
    flex-direction: column;
}

.RequirementProperties {
    flex-grow: 1;
}

.RequirementDescription {
    display: flex;
    flex-direction: column;
}

.Property {
    display: flex;
}

.Description {
    display: flex;
    justify-content: space-between;
}

.Description textarea {
    flex-grow: 2;
    margin-right: 5px;
}

.EditIcon {
    display: flex;
}

.RequirementButtons {
    white-space: nowrap;
}

.LanguageLabel {
    font-weight: bold;
    margin-right: 5px;
}

.DescriptionValue {
    margin-right: 5px;
    background-color: #efefef;
}
