#contact-person-modal {
    width: 400px;
}

#contact-person-container{
    padding: 20px;
}

.contact-person-field{
    display: flex;
    flex-direction: row;
    margin: 15px;
}
.contact-person-field-title{
    font-weight: 700;
    color: #002c3d;
}
.contact-person-field-meaning{
    margin-left: auto;
}
.contact-person-field-meaning input, .contact-person-field-meaning select{
    width: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: content-box;
}
.contact-person-field-meaning select{
    opacity: 1;
    -webkit-appearance: none;
}
#close-bar div{
    color: white;
    font-weight: 700;
    align-self: center;
    margin-left: 20px;
}