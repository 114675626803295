.Sidebar {
    background-color: #002c3d;
    min-height: 100vmax;
    min-width: 200px;
    max-width: 500px;
    grid-row-start: 1;
    row-span: 6;
}

.ProductArea {
    font-weight: 700;
    color: white;
    position: fixed;
    left: 50px;
    padding-top: 10px;
}
