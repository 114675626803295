.ButtonRow {
    background-color:lightgrey;
    display: flex;
    justify-content: space-between;
    position:sticky;
    top:56px;
    z-index: 1000;
    margin-top: 20px;
}

.LeftButtons {
    display: flex;
    justify-content: flex-start;
}
