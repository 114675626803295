.DropDownMenu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
}

.DropDownAngle {
    background-color: #8cc20b;
}

.Menu {
    background-color: #002c3d;
    position: absolute;
    z-index: 2000;
    top: 45px;
    margin: 0 5px;
    padding: 5px;
}
