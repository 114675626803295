.Button {
    background-color: #8cc20b;
    color: white;
    padding: 5px 15px 5px 15px;
    font-size: 16px;
    line-height: 1.6;
    border: none;
    text-decoration: none;
    margin: 5px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.Angle {
    margin: 5px 5px 0px;
}

.Button img {
    max-width: 16px;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    display: block;
}

.Button div {
    display: inline-block;
}

.Button:hover {
    background-color: #699208;
}

.Blue {
    background-color: #39b4e0;
}

.Blue:hover {
    background-color: #177ca0;
}

.DarkBlue {
    background-color: #01415a;
}

.DarkBlue:hover {
    background-color: #043143;
}

.Orange {
    background-color: #ff8c00;
}

.Orange:hover {
    background-color: #ff6200;
}
