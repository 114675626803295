.main-content{
  display: flex;
  padding-right: 7%;
}

.left-side{
  width: 50%;
}
.right-side{
  width: 50%;
}
.Profile{
  color: #002c3d;
  font-weight: 700;
}

.left-side label, .right-side label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}
.left-side input[type=text], .left-side select, .right-side input[type=text], .right-side input[type=date], .right-side textarea{
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: content-box;
}
.left-side select:disabled{
  opacity: 1;
}
.side-container {
  border-radius: 5px;
  padding: 20px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 10px;
}
.col-right {
  margin-right: 10px;
  margin-left: auto;
  width: 50%;
  position: relative;
}

.col-left{
  width: 50%;
}

#Producer-Country option{
  padding: 20px;
  font-size: medium;
  outline: none;
}
.producer-link{
  overflow: hidden;
  width: 100%;
  padding-left: 5px;
  box-sizing: border-box;
  position: absolute;
  top: -5px;
}
