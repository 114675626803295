.ButtonAnswerRow {
    display: flex;
    justify-content: flex-end;
    flex: 2;
}

.ButtonAnswerRow button {
    font-size: 13px;
    line-height: 1.2;
}
