#upload-document {
    display: none;
}

div.UploadDocument label {
    width: 200px;
    box-sizing: border-box;
    background-color: #39b4e0;
    color: white;
    padding: 5px 15px 5px 15px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    border: none;
    text-decoration: none;
    margin: 5px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.UploadDocument label:hover {
    background-color: #177ca0;
}

.UploadDocument img {
    max-width: 16px;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    display: block;
}

.UploadDocument div {
    display: inline-block;
}
