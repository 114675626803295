.Contact-Table{
    width: 100%;
    border-collapse: collapse;
}
.Contact-Table th, .Contact-Table td {
    border: none;
    padding: 10px;
    text-align: start;
}
.Contact-Table {
    border: 1px solid #ddd;
}

.Contact-Table tr:nth-child(even){
    background-color: #f2f2f2;
}

.Contact-Table input[type='text']{
    width: 100%;
    padding:0;
    margin:0;
    height: 30px;
    padding-left: 3px;
}

.Contact-Table select {
    height: 30px;
}

.Contact-Table textarea{
    resize: vertical;
    border: none;
    outline: none;
    width: 100%;
}


.Contact-Table label{
    display: block;
    min-height: 100%;
}
.col-right-plus{
    margin: 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #8cc20b;
}
#add-button-field td{
    text-align: center;
    background-color: white;
}
#row-add-button path{
   height: 100%;
}
.edit-column-person{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.price-table-description{
    text-align: start;
}
.edit-contact-person{
    background: none;
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
    outline: none;
}
.icon-cancel{
    background:url(../../../../../../assets/images/window-close-solid.svg) no-repeat;
    border: none;
    cursor: pointer;
    outline: none;
    display: inline-block;
    padding:0;
    height: 20px;
    width: 20px;
}
.contact-person-country {
   font-weight: 700;
   font-size: medium;
   color: inherit;
   opacity: 1;
   -webkit-appearance: none;
   border: none;
   background-color: inherit;
}
