.default-filter-item{
    margin-left: 10px;
    display: block;
    width: 200px;
}
.default-filter-title{
    font-weight: 700;
    margin-top: 20px;
}
.default-filter{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}
.default-filter-bar{
     position: fixed;
     top: 200px;
     left: 50px;
     display: flex;
     flex-direction: column;
     color: white;
 }
.default-filter-bar input{
    width: fit-content;
    margin-right: 8px;
}
.default-filter-bar-title{
    font-weight: 700;
    margin-top: 20px;
}
.row-filter-item{
     margin-left: 15px;
     display: flex;
     flex-direction: row;
     width: fit-content;
 }
.row-filter-item label{
    flex-wrap: nowrap;
    margin-right: 8px;
}
.row-filter-item input{
    width: 130px;
}

.list-button{
    background-color: #8cc20b;
    color: white;
    padding: 5px 15px 5px 15px;
    font-size: 16px;
    line-height: 1.6;
    border: none;
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 125px;
    margin-top: 10px;
    margin-right: 20px;
    cursor: pointer;
}
.list-button:hover{
    background-color: #699208;
}
.reset-section-button {
    background-color:#01415a;
}

.reset-section-button:hover {
    background-color: #043143;
}
