.documents_list{
    margin: 5px;
    list-style: none;
    padding:2px;
}

.document{
    color: #39b4e0;
    font-weight: 500;
    font-size: small;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.delete-document, .down-document, .down-producer-document{
    background: none;
    width: 13px;
    height: 13px;
    border: none;
    cursor: pointer;
    outline: none;
}
.document_name{
    display: inline-block;
    max-width: 70%;
    word-wrap: break-word;
    cursor: pointer;
}
.delete-button, .document-download{
    display: inline-block;
}
.document-download{
    background: none;
    width: 13px;
    height: 13px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
}
