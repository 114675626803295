.MenuItem {
    background-color: #002c3d;
    color: white;
    border: none;
    text-decoration: none;
    margin: 3px;
    font-size: small;
    text-align: left;
}

.MenuItem:hover {
    background-color: #01415a;
}
