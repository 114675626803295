.Checkbox {
    margin-right: 10px;
}

.ShadowBox {
    margin: 20px 410px 10px 15px;
    padding: 15px 0px 15px 20px;
    background-color: #f5f5f5;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.06), 0 8px 20px rgba(0, 0, 0, 0.025);
}
