.AboutInfo {
    display: flex;
    justify-content: space-evenly;
    padding: 20px 25px 20px 20px;
    background-color: white;
}

.AboutInfo img {
    vertical-align: middle;
    margin-right: 5px;
}
