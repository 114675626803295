.producer-header{
  display: flex;
  flex-direction: row;
  margin: 5px;
  padding-left: 8px;
  margin-bottom: 40px;
  height: 10px
}
.producer-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  position: fixed;
  background-color: white;
  padding: 15px;
  padding-left: 35px;
  z-index: 20;
  top: 50px;
}
.NameText{
  font-size: xx-large;
  margin-left: 5px;
}
.editContainer{
  width: 25px;
  height: 25px;
  margin-left: 30px;
}
.title-edit-button, .edit{
  background:url(../../../../../assets/images/pen-solid.svg) no-repeat;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  outline: none;
  display: inline-block;
  padding: 0;
}

.logo-container{
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding-right: 5%;
}

.text-name{
  margin-right: 30px;
  display: inline-block;
  align-self: flex-end;
  margin-bottom: 30px;
  margin-left: 10px;
}

.logo-img{
  display: inline-block;
  width: 8rem;
  overflow: hidden;
}
.editContainer-logo{
  width: 20px;
  height: 20px;
  margin-left: 30px;
  align-self: flex-end;
}
.editContainer-logo #editContainer-logo-producer, #editContainer-logo-product{
  display: none;
}
.logo{
  height:100%;
  width: 100%;
}
.Export-Profile{
  margin-left: auto;
  margin-right: 5%;
}
.NameText .producer-profile-main-fields{
  padding: 0 10px;
  margin:  0;
  width: 250px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #002c3d;
  outline: none;
  resize: horizontal;
  font-size: 20px;
}
.producer-logo{
  display: flex;
  flex-direction: row;
  width: 50%;
  border-radius: 5px;
  padding: 20px;
}
.product-logo{
  display: flex;
  flex-direction: row;
  width: 50%;
  border-radius: 5px;
  padding: 20px;
}
.Footer-Container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  left: 50%;
  z-index: 20;
}
.deleteContainer-logo{
  width: 20px;
  height: 20px;
  margin-left: 15px;
  align-self: flex-end;
}

.delete-logo{
  background: none;
  width: 18px;
  height: 18px;
  border: none;
  cursor: pointer;
  outline: none;
}
