.customer-profile-field, .customer-profile-selector-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
.customer-profile-container{
    display: flex;
    flex-direction: column;
    width: 88%;
    margin: 4%;
    background-color: rgb(231, 237, 242);
    padding: 2%;
}
.customer-profile-field-left{
    width: 25%;
    color: #002c3d;
    font-weight: 700;
    
}
.customer-profile-field-right{
    width: 75%;
}
.customer-profile-field-right input, .customer-profile-field-right select{
    width: 250px;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.customer-profile-text{
    color: #002c3d;
    font-weight: 600;
}
.customer-profile-selector{
    width: 400px;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.customer-profile-selector-container{
    padding-left: 25%;
}