.Banner {
    background-color: #002c3d;
    opacity: 0.9;
    display: grid;
    grid-template-columns: 10fr 1fr;
    position: fixed;
    width: 50vw;
    padding: 20px 5px;
    top: 0;
    left: 20%;
    z-index: 10000;
}

.Message {
    background-color: #002c3d;
    position: relative;
    color: white;
    padding: 0 20px 0 50px;
}

.MessageButton {
    background-color: #8cc20b;
    color: white;
    margin-right: 25px;
    padding: 5px 15px 5px 15px;
    font-size: 16px;
    border: none;
    display: block;
    height: 30px;
    width: 60px;
}

.MessageOverlay {
    z-index: 1001;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: fixed;
    top: 0;
    left: 0;
}
