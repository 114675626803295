.Price-Table{
    width: 100%;
    border-collapse: collapse;
}
.Price-Table th, .Price-Table td {
    border: none;
    padding: 10px;
    text-align: start;
}
.Price-Table table{
    border: 1px solid #ddd;
}

.Price-Table tr:nth-child(even){
    background-color: #f2f2f2;
}


.Price-Table input[type='text']{
    width: 100%;
    padding:0;
    margin:0;
    height: 30px;
    padding-left: 3px;
}
.Price-Table textarea{
    resize: vertical;
    border: none;
    outline: none;
    width: 100%;
    border: 1px solid;
}
.Th-Individual{
    width: 14%;
}
.Th-Description{
    width: 25%;
}
.Th-Th-UserRangeFrom{
    width: 9%;
}
.Th-UserRangeTo{
    width: 9%;
}
.Th-Period{
    width: 14%;
}
.Th-Category{
    width: 15%;
}
.Th-PriceType{
    width: 14%;
}
.Price-Table td{
    font-weight: 500;
    font-size: medium;
}

.Price-Table label{
    display: block;
    min-height: 100%;
}
.col-right-plus{
    margin: 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #8cc20b;
}
#add-button-field td{
    text-align: center;
    background-color: white;
}
#row-add-button path{
   height: 100%;
}
.edit-column-price{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.price-table-description{
    text-align: start;
}
.edit-price{
    background: none;
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
    outline: none;
}
.icon-cancel{
    background:url(../../../../../../assets/images/window-close-solid.svg) no-repeat;
    border: none;
    cursor: pointer;
    outline: none;
    display: inline-block;
    padding:0;
    height: 20px;
    width: 20px;
}

.Price-Table select{
    height: 30px;
}
