.MenuBar {
    height: content-box;
    background-color: white;
    padding: 10px 10px 5px 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.06), 0 8px 20px rgba(0, 0, 0, 0.025);
    position: sticky;
    top: 0px;
    z-index: 1000;
}

.NavigationBar {
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
}
