.AllForms {
    width: 300px;
    padding: 20px;
    margin: 8px auto;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.AllForms input, .AllForms a, .AllForms button, .AllForms label {
    margin: 20px 0;
    padding: 10px;
    display: block;
    width: 100%;
}

.AllForms a, .AllForms label {
    text-align: center;
    color: #4d4d4d;
}
